import React, {useState} from "react";
import {Box, Stack, Table, TableHead, TableRow, Button} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import GeneralTableRow from "../../components/Table/GeneralTableRow";
import GeneralTableHeaderCell from "../../components/Table/GeneralTableHeaderCell";
import {assertNever} from "../../utils";
import HashButton, {HashType} from "../../components/HashButton";
import GeneralTableBody from "../../components/Table/GeneralTableBody";
import GeneralTableCell from "../../components/Table/GeneralTableCell";
import RewardsPerformanceTooltip from "./Components/RewardsPerformanceTooltip";
import LastEpochPerformanceTooltip from "./Components/LastEpochPerformanceTooltip";
import {useGlobalState} from "../../global-config/GlobalConfig";
import { numberWithCommas } from "../Analytics/utils";

function getSortedValidators(
  validators: any[],
  column: Column,
  direction: "desc" | "asc",
  filterZeroVotingPower = true,
) {
  const validatorsCopy: any[] = JSON.parse(JSON.stringify(validators));
  let filteredValidators = validatorsCopy;
  if (filterZeroVotingPower) {
    filteredValidators = validatorsCopy.filter((validator) => {
      return Number(validator.voting_power) !== 0;
    });
  }
  const orderedValidators = getValidatorsOrderedBy(filteredValidators, column);

  return direction === "desc" ? orderedValidators : orderedValidators.reverse();
}

function getValidatorsOrderedBy(validatorsCopy: any[], column: Column) {
  switch (column) {
    case "amountStaked":
      return validatorsCopy.sort(
        (validator1, validator2) =>
          (validator2.amount ?? 0) - (validator1.amount ?? 0),
      );
    default:
      return validatorsCopy;
  }
}

type ValidatorHeaderCellProps = {
  column: Column;
  direction?: "desc" | "asc";
  setDirection?: (dir: "desc" | "asc") => void;
  setSortColumn: (col: Column) => void;
};

function ValidatorHeaderCell({
  column,
  direction,
  setDirection,
  setSortColumn,
}: ValidatorHeaderCellProps) {
  switch (column) {
    case "address":
      return <GeneralTableHeaderCell header="Operator Address" />;
    case "withdraw":
      return <GeneralTableHeaderCell header="Action" textAlignRight={true} />;
    case "amountStaked":
      return <GeneralTableHeaderCell header="Amount Staked" textAlignRight={true} />;
    default:
      return assertNever(column);
  }
}

type ValidatorCellProps = {
  validator: any;
  handleClick: any;
};

export function OperatorAddrCell({validator}: ValidatorCellProps) {
  return (
    <GeneralTableCell sx={{textAlign: "left"}}>
      <HashButton
        hash={validator.signer}
        type={HashType.NODE}
        isValidator
      />
    </GeneralTableCell>
  );
}

function WithdrawCell({validator, handleClick}: ValidatorCellProps) {
  return (
    <GeneralTableCell sx={{textAlign: "right"}}>
      <Button
        color="primary"
        variant="text"
        onClick={async () => {
          await handleClick('unstake', validator);
        }}
        sx={{
          mb: 0,
          p: 0,
          "&:hover": {
            background: "transparent",
          },
        }}
        startIcon={<GroupRemoveIcon />}
      >
        Withdraw
      </Button>
    </GeneralTableCell>
  );
}


function AmountStakedCell({validator}: ValidatorCellProps) {
  return (
    <GeneralTableCell sx={{textAlign: "right"}}>
      {numberWithCommas(Number(validator.amount).toFixed(2))} CDT
    </GeneralTableCell>
  );
}

const ValidatorCells = Object.freeze({
  address: OperatorAddrCell,
  amountStaked: AmountStakedCell,
  withdraw: WithdrawCell
});

type Column = keyof typeof ValidatorCells;

const COLUMNS: Column[] = [
  "address",
  "amountStaked",
  "withdraw"
];

type ValidatorRowProps = {
  validator: any;
  columns: Column[];
  handleClick: any;
};

function ValidatorRow({validator, columns, handleClick}: ValidatorRowProps) {
  return (
    <GeneralTableRow>
      {columns.map((column) => {
        const Cell = ValidatorCells[column];
        return <Cell key={column} validator={validator} handleClick={handleClick} />;
      })}
    </GeneralTableRow>
  );
}

export function CurrentStakingTable({ values, handleClick }: any) {
  const [state] = useGlobalState();

  const [sortColumn, setSortColumn] = useState<Column>('amountStaked');
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">("desc");
  const sortedValues = getSortedValidators(
    values,
    sortColumn,
    sortDirection,
  );

  const columns = COLUMNS;

  return (
    <Table>
      <TableHead>
        <TableRow sx={{verticalAlign: "bottom"}}>
          {columns.map((column) => (
            <ValidatorHeaderCell
              key={column}
              column={column}
              direction={sortColumn === column ? sortDirection : undefined}
              setDirection={setSortDirection}
              setSortColumn={setSortColumn}
            />
          ))}
        </TableRow>
      </TableHead>
      <GeneralTableBody>
        {sortedValues.map((stake: any, i: number) => {
          return (
            <ValidatorRow key={i} validator={stake} columns={columns} handleClick={handleClick} />
          );
        })}
      </GeneralTableBody>
    </Table>
  );
}
