import {Dialog, DialogProps, DialogTitle, IconButton, Stack} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {grey} from "../themes/colors/colorPalette";

interface StyledDialogTitleProps extends DialogProps {
  handleDialogClose: () => void;
  children: React.ReactNode;
  title: string
}

export default function StyledDialogTitle({
  handleDialogClose,
  children,
  title,
  ...props
}: StyledDialogTitleProps) {
  return (
    <Dialog onClose={handleDialogClose} {...props}>
      <DialogTitle style={{ fontSize: '16px' }}> 
          {title}
      </DialogTitle>
      <IconButton
        onClick={handleDialogClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 4,
          color: grey[450],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack marginX={[3, 4]} marginY={4}>
        {children}
      </Stack>
    </Dialog>
  );
}
