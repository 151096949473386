import {Box, Button, TextField, Typography, Input} from "@mui/material";
import {styled, useTheme} from "@mui/system";
import {useEffect, useRef, useState} from "react";
import {grey, primary} from "../../../themes/colors/colorPalette";
import StyledDialog from "../../../components/StyledDialog";
import {RestartAlt} from "@mui/icons-material";
import StyledDialogTitle from "../../../components/StyledDialogTitle";
import { numberWithCommas } from "../../Analytics/utils";

const StyledCanvas = styled("canvas")`
  @media (max-width: 480px) {
    width: 130px;
  }
`;

interface StakeModalProps {
  open: boolean;
  handleDialogClose: any;
  handleStake: any;
  signer: any;
  wallet: any;
  balance: number;
  stakedData: any;
}

export default function StakeModal({
  open,
  handleDialogClose,
  handleStake,
  signer,
  wallet,
  balance,
  stakedData
}: StakeModalProps) {
  const theme = useTheme();
  const ref = useRef<HTMLCanvasElement>(null);
  const [amount, setAmount] = useState('0');

  const onSubmit = async () => {
    const result = await handleStake(signer, amount);
    if (result) {
      handleDialogClose();
    }
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const stakeDataValue = stakedData.find((x: any) => x.signer === signer.address);

  return (
    <StyledDialogTitle open={open} title={signer.name} handleDialogClose={handleDialogClose} sx={{}}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginTop={0}
      >
        <table style={{ minWidth: '100%' }}>
          <tr>
            <td style={{ fontSize:'14px' }}>Staked:</td>
            <td style={{ fontSize:'14px', fontWeight: '600', textAlign: 'end' }}>{ stakeDataValue !== undefined ? `${numberWithCommas(stakeDataValue.amount)}` : '0' } CDT</td>
          </tr>
          <tr>
            <td style={{ fontSize:'14px' }}>Minimum stake:</td>
            <td style={{ fontSize:'14px', fontWeight: '600', textAlign: 'end' }}>0.1 CDT</td>
          </tr>
          <tr>
            <td style={{ fontSize:'14px' }}>Available balance:</td>
            <td style={{ fontSize:'14px', fontWeight: '600', textAlign: 'end' }}>{`${numberWithCommas(balance.toFixed(2))}`} CDT</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <TextField
                value={amount}
                placeholder="Amount"
                onChange={onChangeAmount}
                sx={{width: "100%"}}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
                <div style={{ position: 'relative' }}>
                  <input value={amount} type="range" style={{ width: '100%'}} min="0" max={Number(balance).toFixed(0)} onChange={onChangeAmount}  step="1"></input>
                  <div style={{ width: '100%', color: 'gray', position: 'absolute', top: '5px' }}>
                    <div style={{ left: '0%', position: 'absolute', transform: 'translateX(-50%)', fontSize: '14px', color: '#808695', marginTop: '15px' }}>0%</div>
                    <div style={{ left: '25%', position: 'absolute', transform: 'translateX(-50%)', fontSize: '14px', color: '#808695', marginTop: '15px' }}>25%</div>
                    <div style={{ left: '50%', position: 'absolute', transform: 'translateX(-50%)', fontSize: '14px', color: '#808695', marginTop: '15px' }}>50%</div>
                    <div style={{ left: '75%', position: 'absolute', transform: 'translateX(-50%)', fontSize: '14px', color: '#808695', marginTop: '15px' }}>75%</div>
                    <div style={{ left: '100%', position: 'absolute', transform: 'translateX(-50%)', fontSize: '14px', color: '#808695', marginTop: '15px' }}>100%</div>
                  </div>
                </div>
            </td>
          </tr>
        </table>
      </Box>
      { wallet === undefined && (<Typography fontSize={12} marginTop={4} color={'#bb3232'} fontWeight={400}>{`Wallet not connected`}</Typography>)}
      <Box
        marginTop={3}
        textAlign={'center'}
      >
      <Button
          variant="contained"
          onClick={onSubmit}
          sx={{
            width: "100%",
            height: "3rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          Stake
        </Button>
      </Box>
      <Box
        marginTop={3}
        textAlign={'start'}
      >
        <table style={{ width: '100%' }}>
          <tr>
            <td style={{ fontSize:'14px', fontWeight: '600' }}>Note</td>
          </tr>
          <tr>
            <td style={{ fontSize:'14px' }}>Staking rewards are restaked every blocks.</td>
          </tr>
          <tr>
            <td style={{ fontSize:'14px' }}>Staking rewards are automatically compounded.</td>
          </tr>
          <tr>
            <td style={{ fontSize:'14px' }}>The unstaking process takes 1 block of duration.</td>
          </tr>
        </table>
      </Box>
    </StyledDialogTitle>
  );
}
