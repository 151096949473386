import {Stack, Box, Grid, Typography, Button} from "@mui/material";
import {useGlobalState} from "../../global-config/GlobalConfig";
import PageHeader from "../layout/PageHeader";
import StakingPageTabs from "./Tabs";
import { useNetworkSelector } from "../../global-config/network-selection";
import { useQuery } from "@tanstack/react-query";
import { api_getMyDelegations, api_getNetwork } from "../../queries/api";
import TotalTransactions from "../Analytics/NetworkInfo/TotalTransactions";
import TotalSupply from "../Analytics/NetworkInfo/TotalSupply";
import { numberWithCommas } from "../Analytics/utils";
import MetricCard from "../Analytics/NetworkInfo/MetricCard";
import { CardWithStyle } from "../../components/Card";
import { Banner } from "../../components/Banner";
import { useState } from "react";
import { networksURLS } from "../../constants";
import Web3 from "web3";
import HashButton, { HashType } from "../../components/HashButton";
import StakeModal from "./Components/StakeModal";
import { getABI, getContract } from "../utils";
import { ethers } from "ethers";
import { CurrentStakingTable } from "./CurrentStakingTable";
import StyledTab from "../../components/StyledTab";
import StyledTabs from "../../components/StyledTabs";
import UnstakeModal from "./Components/UnstakeModal";

export default function StakingPage() {
  const [state] = useGlobalState();
  const [selectedNetwork] = useNetworkSelector();
  const currentPage = 0;
  const [wallet, setWallet] = useState<any>(undefined);
  const [web3Provider, setWeb3Provider] = useState<any>(undefined);
  const [web3, setWeb3] = useState<any>(undefined);
  const [availableBalance, setAvailableBalance] = useState<any>(undefined);
  const [stakeModalOpen, setStakeModalOpen] = useState<boolean>(false);
  const [unstakeModalOpen, setUnstakeModalOpen] = useState<boolean>(false);
  const [selectedSigner, setSelectedSigner] = useState<any>(undefined);

  const nodesResult = useQuery({
    queryKey: ["api_getNetwork", currentPage],
    queryFn: async () => {
      const queryResult = await api_getNetwork(selectedNetwork, currentPage, 50);
      return queryResult.result;
    },
  });

  const stakedData = useQuery({
    queryKey: ["api_getMyDelegations", currentPage],
    queryFn: async () => {
      const queryResult = await api_getMyDelegations(selectedNetwork, wallet);
      return queryResult.result;
    },
    enabled: wallet !== undefined,
    refetchInterval: 10000,
    initialData: []
  });

  const disconnect = () => {

    setWallet(undefined);
  };

  const onConnect = async (account: string, web3: Web3, web3Provider: any) => {
    setWallet(account);
    const balanceCDT = web3.utils.fromWei(await web3.eth.getBalance(account), 'ether');

    setAvailableBalance(Number(balanceCDT));
  }

  const connectWallet = async () => {
    if (!(window as any).ethereum) {
        alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        return ;
    }
    try {
        // check if the chain to connect to is installed
        await (window as any).ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x33F' }], // chainId must be in hexadecimal numbers
        });
    } catch (error: any) {
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (error.code === 4902) {
        try {
            await (window as any).ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: '0x33F',
                        rpcUrl: networksURLS[selectedNetwork],
                    },
                ],
            });
        } catch (addError) {
            console.error(addError);
        }
        }
        console.error(error);
    }

    try {
        const web3Provider = new Web3.providers.HttpProvider(networksURLS[selectedNetwork]);
        const web3 = new Web3(web3Provider);
        setWeb3Provider(web3Provider);
        setWeb3(web3);
        web3.eth.getBlockNumber().then((result: any) => {
            console.log("Latest Ethereum Block is", Number(result));
        });

        let result = await (window as any).ethereum.request({
            method: 'eth_requestAccounts',
            params: []
        });

        if (result !== undefined && result.length > 0) {
          onConnect(result[0], web3, web3Provider);
        }

        (window as any).ethereum.on('accountsChanged', (accounts: any) => {
            if (accounts !== undefined && accounts.length > 0) {
              onConnect(accounts[0], web3, web3Provider);
            }
        });

        (window as any).ethereum.on('chainChanged', (networkId: any) => {
            console.log(networkId);
            disconnect();
        });   
    } catch (error) {
        console.log(error);
    }
  }

  const onClickStake = async (action: string, signer: any) => {
    if (action === 'stake') {
      setSelectedSigner(signer);
      setStakeModalOpen(true);
    } else {
      setSelectedSigner(signer);
      setUnstakeModalOpen(true);
    }
  }

  const onClickWithdraw = async (stake: any) => {
    //
  }

  const onUnstake = async (signer: any, amount: string) => {
    if (isNaN(Number(amount))) {
      return false;
    }
    if (signer === undefined) {
      return false;
    }
    const amountInInteger = Number(amount);

    const instructionAddress = '0x0000000000000000000000000000000000000001';
    const instructionContractABI = getABI(
      [
        'function withdraw(address signer, uint256 amount) external'
      ]
    );

    const tempProvider = new ethers.providers.Web3Provider((window as any).ethereum);
    const tempSigner = tempProvider.getSigner();
    const tempContract = new ethers.Contract(instructionAddress, instructionContractABI, tempSigner);

    await tempContract.withdraw(signer.signer, web3.utils.toWei(amountInInteger.toString(), 'ether'));
    return true;
  }

  const onStake = async (signer: any, amount: string) => {
    if (isNaN(Number(amount))) {
      return false;
    }
    if (signer === undefined) {
      return false;
    }
    const amountInInteger = Number(amount);
    console.log(web3);

    const instructionAddress = '0x0000000000000000000000000000000000000001';
    const instructionContractABI = getABI(
      [
        'function delegate(address signer, uint256 amount) external'
      ]
    );

    const tempProvider = new ethers.providers.Web3Provider((window as any).ethereum);
    const tempSigner = tempProvider.getSigner();
    const tempContract = new ethers.Contract(instructionAddress, instructionContractABI, tempSigner);

    await tempContract.delegate(signer.address, web3.utils.toWei(amountInInteger.toString(), 'ether'));
    return true;
  }

  return (
    <Box>
      <PageHeader />
      <Typography variant="h3" marginBottom={2}>
        Staking
      </Typography>
        <Grid container spacing={3} marginTop={3}>
          <Grid item xs={12} md={12} lg={12} marginBottom={0}>
            <Grid
              container
              spacing={3}
              direction="row"
              sx={{alignContent: "flex-start"}}
              marginBottom={'20px'}
            >
              <Grid item xs={12} md={6} lg={3}>
                <CardWithStyle height={120}>
                  <Stack spacing={3}>
                  <Stack direction="row" spacing={1} alignItems="center">
                      <Typography fontSize={20}></Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography fontSize={20}>My Staking:</Typography>
                    </Stack>
                  </Stack>
                </CardWithStyle>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <CardWithStyle height={120}>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography fontSize={12}>Available Balance</Typography>
                  </Stack>
                  <Typography fontSize={20} fontWeight={400}>{ availableBalance !== undefined ? `${numberWithCommas(Number(availableBalance).toFixed(2))} CDT` : '...'}</Typography>
                </Stack>
              </CardWithStyle>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <CardWithStyle height={120}>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography fontSize={12}>Staked Balance</Typography>
                  </Stack>
                  <Typography fontSize={20} fontWeight={400}>{stakedData.data !== undefined ? `${numberWithCommas(stakedData.data.reduce((acc: any, x: any) => acc + x.amount, 0).toFixed(2))} CDT` : '...'}</Typography>
                </Stack>
              </CardWithStyle>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <CardWithStyle height={120}>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography fontSize={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>Wallet { wallet !== undefined && (<Typography fontSize={12} color={'#32bb84'} fontWeight={400}>{`Connected`}</Typography>)}</Typography>
                  </Stack>
                  { wallet === undefined && (<Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "3rem",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "24px",
                    }}
                    onClick={connectWallet}
                  >
                    Connect Wallet
                  </Button>)}
                  { wallet !== undefined && (<HashButton
                    hash={wallet}
                    type={HashType.ACCOUNT}
                    isValidator
                  />)}
                </Stack>
              </CardWithStyle>
            </Grid>
          </Grid>
          <Banner pillText="INFO" pillColor="warning" sx={{marginBottom: '20px', marginTop: '0px'}}>
            Please note: Staking rewards will be added to your staking every blocks. Unstaking process will take a period of 1 block to complete.
          </Banner>
          {/* <StyledTabs value={'UNSTAKE'} onChange={() => {}} style={{ marginTop: '20px' }}>
            <StyledTab
                  icon={
                    <Typography
                      sx={{
                        backgroundColor: "#8B5CF6",
                        color: "#ffffff",
                        borderRadius: 1,
                        paddingX: 1,
                        minWidth: "3.5rem",
                        height: "1.5rem",
                      }}
                    >
                      BETA
                    </Typography>
                  }
                  key={'19'}
                  value={"UNSTAKE"}
                  label={"UNSTAKE"}
                  isFirst={true}
                  isLast={true}
                />
          </StyledTabs>
          { stakedData.data && <CurrentStakingTable values={stakedData.data} handleClick={onClickWithdraw} /> } */}
        </Grid>
      </Grid>
      { nodesResult.data && <StakingPageTabs nodes={nodesResult.data} data={stakedData.data} handleClick={onClickStake} /> }
      {stakeModalOpen === true && (<StakeModal
        open={stakeModalOpen}
        handleDialogClose={() => setStakeModalOpen(() => false)}
        handleStake={onStake}
        signer={selectedSigner}
        wallet={wallet}
        balance={availableBalance !== undefined ? availableBalance : 0}
        stakedData={stakedData.data}
      />)}
      {unstakeModalOpen === true && (<UnstakeModal
        open={unstakeModalOpen}
        handleDialogClose={() => setUnstakeModalOpen(() => false)}
        handleStake={onUnstake}
        signer={selectedSigner}
        wallet={wallet}
        balance={availableBalance !== undefined ? availableBalance : 0}
        stakedData={stakedData.data}
      />)}
    </Box>
  );
}
