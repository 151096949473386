import * as React from "react";
import {Box, Typography} from "@mui/material";
import {assertNever} from "../../utils";
import StyledTabs from "../../components/StyledTabs";
import StyledTab from "../../components/StyledTab";
import {useParams} from "react-router-dom";
import {Network, NetworkName} from "../../constants";
import {useGlobalState} from "../../global-config/GlobalConfig";
import {useNavigate} from "../../routing";
import { StakingTable } from "./StakingTable";
import { CurrentStakingTable } from "./CurrentStakingTable";

enum VALIDATORS_TAB_VALUE {
  STAKE = "stake",
  UNSTAKE = "unstake"
}
const VALIDATORS_TAB_VALUES: VALIDATORS_TAB_VALUE[] = [
  VALIDATORS_TAB_VALUE.STAKE,
  VALIDATORS_TAB_VALUE.UNSTAKE
];

function getTabLabel(value: VALIDATORS_TAB_VALUE): string {
  switch (value) {
    case VALIDATORS_TAB_VALUE.STAKE:
      return "STAKE";
    case VALIDATORS_TAB_VALUE.UNSTAKE:
      return "UNSTAKE";
    default:
      return assertNever(value);
  }
}

type TabPanelProps = {
  value: VALIDATORS_TAB_VALUE;
  nodes: any[];
  handleClick: any;
  data: any;
};

function TabPanel({value, nodes, data, handleClick}: TabPanelProps): JSX.Element {
  if (value === VALIDATORS_TAB_VALUE.STAKE) {
    return <StakingTable value={value} nodes={nodes} handleClick={handleClick}/>;
  } else {
    return <CurrentStakingTable value={value} values={data} handleClick={handleClick}/>;
  }
}

export default function StakingPageTabs({ nodes, data, handleClick }: any): JSX.Element {
  const [state] = useGlobalState();
  const {tab} = useParams();
  const navigate = useNavigate();

  const value = (tab as VALIDATORS_TAB_VALUE);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: VALIDATORS_TAB_VALUE,
  ) => {
    navigate(`/staking/${newValue}`);
  };

  return (
    <Box sx={{width: "100%"}}>
      <Box>
        <StyledTabs value={value} onChange={handleChange}>
          {VALIDATORS_TAB_VALUES.map((value, i) =>
            <StyledTab
            icon={
              <Typography
                sx={{
                  backgroundColor: "#8B5CF6",
                  color: "#ffffff",
                  borderRadius: 1,
                  paddingX: 1,
                  minWidth: "3.5rem",
                  height: "1.5rem",
                }}
              >
                BETA
              </Typography>
            }
            key={i}
            value={value}
            label={getTabLabel(value)}
            isFirst={i === 0}
            isLast={i === VALIDATORS_TAB_VALUES.length - 1}
          />
          )}
        </StyledTabs>
      </Box>
      <Box sx={{width: "auto", overflowX: "auto"}}>
        <TabPanel value={value} nodes={nodes} data={data} handleClick={handleClick} />
      </Box>
    </Box>
  );
}
